<template>
    <div class="pages-container">
        <h1 class="title">{{ $t('pages.cities') }}</h1>
        <CRButton class="mt-25" @click="toCreateCity"> {{ $t('buttons.newCity') }} </CRButton>
        <div v-if="cities.length === 0" class="empty__wrapper">
            <h1>{{ $t('pages.noCitiesAdded') }}</h1>
        </div>
        <div class="card__wrapper" v-else>
            <CityCard v-for="city in cities" :city="city" @delete="onDelete" @click="onCityClick(city.id)" :key="city.id"/>
        </div>
    </div>
</template>
<script>
import CRButton from '@/components/Common/Buttons/CRButton.vue';
import CityCard from '@/components/Admin/City/CityCard.vue';
import { mapGetters, mapActions } from 'vuex';
export default {
    components: {
        CRButton,
        CityCard,
    },
    data() {
        return {
            
        }
    },
    computed: {
        ...mapGetters('city', ['cities']),
    },
    methods: {
        ...mapActions('city', ['destroyCity']),
        toCreateCity() {
            this.$router.push({ name: 'createCity' });
        },
        onDelete(id) {
            if(confirm('Ar tikrai norite ištrinti miestą, ištrynus miestą kuriame yra užsakymų gali atsirasti klaidų.')) {
                this.destroyCity(id);
            }
        },
        onCityClick(id) {
            this.$router.push({
                name: 'updateCity',
                params: { city_id: id },
            });
        },
    },
    async mounted() {
     
    }
}
</script>
<style lang="scss" scoped>
    .pages-container {
        padding-bottom: 30px;

        .empty__wrapper {
            @include column-align-center-justify-center;

            min-height: 300px;
            width: 100%;
        }

        .card__wrapper {
            margin-top: 14px;

            .card {
                margin-bottom: 25px;

                &:hover {
                    transform: scale(1);
                }
            }
        }
    }
</style>