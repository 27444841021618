<template>
    <div class="city-card">
        <div class="city-card__wrap">
            <div class="city-card__item">
                <div>{{ city.name }}</div>
            </div>
            <div class="city-card__item">{{ city.preOrder === 0 ? 'Reguliarus' : 'Išankstinis' }}</div>
            <div class="city-card__item">{{ city.active === false ? 'Išjungtas' : 'Įjungtas' }}</div>
            <div class="city-card__item">
                <CRButton class="close-btn" close @click.stop="deleteCity" />
            </div>
        </div>
    </div>
</template>
<script>
import CRButton from '@/components/Common/Buttons/CRButton.vue';
export default {
    components: {
        CRButton,
    },
    props: {
        city: {},
    },
    methods: {
        deleteCity() {
            this.$emit('delete', this.city.id);
        },
    },
    emits: ['delete']
}
</script>
<style lang="scss" scoped>

.url {
    font-size: 12px;
    font-weight: bold;
    margin-top: 10px;
    &:hover {
        text-decoration: underline;
    }
}

.city-card {
    margin-top: 10px;
    padding: 10px;
    width: 100%;
    border-radius: 10px;
    border: solid 1px #ddd;
    height: 70px;
    cursor: pointer;
    &:hover { 
        background: #e9e9e9;
    }

    &__wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__item {
        
    }
}
</style>